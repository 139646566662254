.project-card-button {
    text-decoration: none;
}

.project-card-content {
    max-width: 400px;
    min-height: 440px;
}

.project-card-description {
    min-height: 120px;
}