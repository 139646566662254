.about-body {
    margin: 32px 0px;
    min-width: 320px;
    height: 100%;
}

.about-picture {
    max-width: 15rem;
    border-radius: 50%;
}

.about-description {
    text-align: center;
}

.about-biography {
    text-align: center;
}

.about-biography-text {
    max-width: 400px;
    text-align: justify;
    padding: 0px 24px;
    font-size: 1.25rem;
    margin: auto;
}

.about-skills-header {
    margin-bottom: 16px;;
}

.about-skills {
    max-width: 800px;
    margin: 0px 16px 32px 16px;
}

.about-button-container {
    text-align: center;
    margin-bottom: 56px !important;
}

.about-button {
    width: 224px;
    height: 56px;
}

.about-github-link {
    text-decoration: none;
}