.footer-content {
    width: 100%;
    height: 80px;
    background-color: #718792;
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 16px 0 0 0;
}

.footer-link {
    text-decoration: none;
    color: black;
}