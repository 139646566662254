.contact-body {
    margin: 48px 16px;
}

.contact-button {
    width: 304px;
}

.contact-text {
    max-width: 600px;
    text-align: center;
}